//swiper(スライダー実装)
const defaultSettingSwiper = {
  pagination: {
    el: ".swiper-pagination",
  },
  effect: "slide",
  spaceBetween: 0,
  grabCursor: true,
  centeredSlides: false,
  slidesPerView: "auto",
  loop: true,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  }
}
new Swiper(".tourSwiper", {
  ...defaultSettingSwiper,
  navigation: {
    nextEl: ".tourSwiperButtonNext",
    prevEl: ".tourSwiperButtonPrev",
  },
});
new Swiper(".reportParentSwiper", {
  ...defaultSettingSwiper,
  navigation: {
    nextEl: ".reportParentSwiperNext",
    prevEl: ".reportParentSwiperPrev",
  },
});
new Swiper(".reportChildSwiper", {
  ...defaultSettingSwiper,
  spaceBetween: 0,
  loop: false,
  navigation: {
    nextEl: ".reportChildSwiperNext",
    prevEl: ".reportChildSwiperPrev",
  },
});

//横スクロール用
$.prototype.draggable = function () {
  let t;
  $(this).each((_, e) => {
    $(e).mousedown((e2) => {
      e2.preventDefault();
      t = $(e);
      $(e).data({
        down: true,
        x: e2.clientX,
        y: e2.clientY,
        left: $(e).scrollLeft(),
        top: $(e).scrollTop(),
      });
    });
  });
  $(document)
    .mousemove((e) => {
      if ($(t).data("down")) {
        e.preventDefault();
        $(t).scrollLeft($(t).data("x") + $(t).data("left") - e.clientX);
        $(t).scrollTop($(t).data("y") + $(t).data("top") - e.clientY);
      }
    })
    .mouseup(() => {
      $(t).data("down", false);
    });
};
$(".testimonialsCardFlex").draggable();

//モーダルウィンドウ用
const buttonsOpen = document.querySelectorAll(".modalOpen");
const modal = document.getElementById("easyModal");
const body = document.getElementById("index");
const buttonClose = document.getElementById("modalClose");
const buttonClosePc = document.getElementById("modalClosePc");

buttonsOpen.forEach((button) => button.addEventListener("click", () => {
  modal.classList.add("modalOpen");
  body.classList.add("bodyFixed");
}));

buttonClose.addEventListener("click", () => {
  modal.classList.remove("modalOpen");
  body.classList.remove("bodyFixed");
});

buttonClosePc.addEventListener("click", () => {
  modal.classList.remove("modalOpen");
  body.classList.remove("bodyFixed");
});

addEventListener("click", (e) => {
  if (e.target == modal) {
    modal.classList.remove("modalOpen");
    body.classList.remove("bodyFixed");
  }
});

//アコーディオンをクリックした時の動作
$(".faqBigTitle").on("click", function() {
  $(".faqBox").slideUp(500);

  const findElm = $(this).next(".faqBox");

  if($(this).hasClass("faqClose")){
    $(this).removeClass("faqClose");
  }else{
    $(".faqClose").removeClass("faqClose");
    $(this).addClass("faqClose");
    $(findElm).slideDown(500);
  }
});

$(".faqBigTitleInner").on("click", function() {
  $(".faqBoxInner").slideUp(100);

  const findElmInner = $(this).next(".faqBoxInner");
  const findElmInnerLi = $(this).parent(".faqAccordionAreaLiInner");

  if($(this).hasClass("faqCloseInner")){
    $(this).removeClass("faqCloseInner");
    $(findElmInnerLi).removeClass("faqAccordionAreaLiInnerOpen");
  }else{
    $(".faqCloseInner").removeClass("faqCloseInner");
    $(".faqAccordionAreaLiInnerOpen").removeClass("faqAccordionAreaLiInnerOpen");
    $(this).addClass("faqCloseInner");
    $(findElmInnerLi).addClass("faqAccordionAreaLiInnerOpen");
    $(findElmInner).slideDown(100);
  }
});

//マルチセレクト
const $select2 = $(".js-select2");
$select2.select2({
  width: "100%",
  closeOnSelect : false,
  placeholder : "選択してください",
  allowHtml: true,
  allowClear: true,
  tags: false
});

// select2の検索ボックスを非表示にする
$select2.on("select2:opening select2:closing", (event) => {
  const $searchfield = $(event.target).parent().find(".select2-search__field");
  $searchfield.prop("disabled", true);
});

// マルチセレクトで選択された項目をクリア
$(document).ready(function() {
  $("#clearButton").click(() => {
    $(".js-select2").each(function() {
      $(this).val(null).trigger("change");
    });

    let q_freeword = document.querySelector("#q_freeword");
    let q_daycare_center_name = document.querySelector("#q_daycare_center_name");
    const q_freeword_value = document.querySelector("#q_freeword").value;
    const q_daycare_center_name_value = document.querySelector("#q_daycare_center_name").value;

    document.getElementById("top_form").reset();

    q_freeword.value = q_freeword_value;
    q_daycare_center_name.value = q_daycare_center_name_value;
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const errorToast = document.querySelector(".toast.toastNg");
  let ngToastBody = "";
  // 必須チェック
  document.querySelector("#top_form").addEventListener("submit", (event) => {
    const selectedTargetElements = [];
    document.querySelectorAll(".search-target .must-required").forEach((element) => {
      const classList = element.closest(".search-target").classList;
      if (!classList.contains("hidden")) {
        // hidden クラスが存在しない場合のみ対象
        selectedTargetElements.push(element);
        if (classList.contains("area-search")) {
          ngToastBody = "都道府県、市区町村を選択してください";
        } else if (classList.contains("station-search")) {
          ngToastBody = "駅を選択してください";
        } else {
          ngToastBody = "保育園を選択してください";
        }
      }
    });
    const everyInputOk = selectedTargetElements.every((input) => {
      return input.value.length > 0;
    });
    if (everyInputOk) {
      return;
    }

    event.preventDefault();
    errorToast.querySelector(".toast-bodyNg").innerText = ngToastBody;
    errorToast.classList.remove("hide");
  });

  // 保育園名の部分一致検索対応
  $("#q_daycare_center_id").select2({
    width: "100%",
    ajax: {
      url:"/api/v1/suggest_daycare_centers",
      dataType: "json",
      delay: 50,
      data: function(params) {
        const prefId = document.querySelector("#for_daycare_center_pref").value;
        return { term: params.term, prefecture_id: prefId, can_posting: true };
      },
      processResults: function (data, params) {
        return { results: $.map(data, function(obj) {
            return { id: obj.id, text: obj.text };
          })};
      }
    },
    placeholder: "検索してください",
  }).addClass("must-required");
  // 駅名の部分一致検索対応
  $("#q_station_id").select2({
    width: "100%",
    ajax: {
      url:"/api/v1/suggest_stations",
      dataType: "json",
      delay: 50,
      data: function(params) {
        return { term: params.term };
      },
      processResults: function (data, params) {
        return { results: $.map(data, function(obj) {
            return {...obj};
          })};
      }
    },
    placeholder: "検索してください",
  }).addClass("must-required");
});

// time picker (参考: https://timepicker.co/)
const timepickerOption = {
  timeFormat: "HH:mm",
  interval: 15,
  minTime: "0",
  maxTime: "23:45",
  dynamic: false,
  dropdown: true,
  scrollbar: true
}

$(document).ready(function () {
  $("input.timepicker.open").timepicker({
    ...timepickerOption,
    defaultTime: "7:00",
  });
});

$(document).ready(function () {
  $("input.timepicker.close").timepicker({
    ...timepickerOption,
    defaultTime: "8:00",
  });
});


//検索フォーム住所のplaceholderのカラーを設定
function updateTextColor(element) {
  const selectedValue = element.value;
  const textColor = (selectedValue === "") ? "#999999" : "#323232";
  element.style.color = textColor;

  // 都道府県変更した場合に市区町村がリセットされるため、色を変更する
  if (element.id === "q_prefecture_id") {
    const q_city_id = document.getElementById("q_city_id");
    q_city_id.style.color = "#999999";
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const q_prefecture_id = document.getElementById("q_prefecture_id");
  const for_daycare_center_pref = document.getElementById("for_daycare_center_pref");
  const q_city_id = document.getElementById("q_city_id");

  updateTextColor(q_prefecture_id);
  updateTextColor(for_daycare_center_pref);
  updateTextColor(q_city_id);

  q_prefecture_id.addEventListener("change", () => updateTextColor(q_prefecture_id));
  for_daycare_center_pref.addEventListener("change", () => updateTextColor(for_daycare_center_pref));
  q_city_id.addEventListener("change", () => updateTextColor(q_city_id));
});

//検索エリアのタブ切り替えによる余白調整
document.addEventListener("DOMContentLoaded", () => {
  const tabs = document.querySelectorAll(".tabs a[data-target]");
  const aboutSection = document.querySelector(".about");

  tabs.forEach(tab => {
    tab.addEventListener("click", (event) => {
      const target = event.currentTarget.dataset.target;
      const classToAdd = `about${target.charAt(0).toUpperCase()}${target.slice(1)}`;

      // 既存の about クラスがついた要素に余白調整用のクラスを追加
      if (aboutSection) {
        aboutSection.classList.add(classToAdd);

        // タブ切り替え時に余白調整用クラスを削除
        tabs.forEach(otherTab => {
          if (otherTab !== event.currentTarget) {
            const otherTarget = otherTab.dataset.target;
            const classToRemove = `about${otherTarget.charAt(0).toUpperCase()}${otherTarget.slice(1)}`;
            aboutSection.classList.remove(classToRemove);
          }
        });
      }
    });
  });
});

